export const path = {
  app: {
    login: '/login',
    signUp: '/Sign-up',
    signUpSuccess: '/sign-up-success',
    resetPassword: '/reset-password',
    forgotPassword: '/forgot-password',
    checkEmail: '/check-email',
    thankYou: '/thank-you',
    agentSignup:'/agent-signup',
    verifyUserToken:'/verify-user-token',
    verifyConsumerCustomer:'/verify-consumer-customer',
    verifyPartnerAgent:'/verify-partner-agent',
    verifyCustomer:'/verify-customer',
    verifyAdmin:'/verify-admin',
    verifyNewAgency:'/verify-new-agency',
    paymentByCustomer:'/payment-by-customer',
    verifyCustomerPayment:'/verify-customer-payment',
    producerAgreement:'/producer-agreement-signature',
    additionalQuestion:'/additional-question',
    verifyCustomerApplication:'/verify-customer-application',
    customerThankYou:'/customer-thank-you'

  },
  products: {
    root: '/products',
    allApplications: '/products/applications/all',
    applications: '/products/product-applications',
    carrierApplications: '/products/carrier-applications'
  },
  
  serviceRequests: { root: '/service-requests', add: '/service-requests/add' },
  reports: { root: '/reports' },
  policies: { root: '/policies' },
  agencies: {
    root: '/agencies',
    agents: '/agencies/agents',
    documents: '/agencies/documents',
    authAgencies: '/agencies/authorized-agencies',
    commissions: '/agencies/commissions',
    authProducts: '/agencies/authorized-products'
  },
  mgas: {
    root: '/mgas',
    rating: '/mgas/rating-systems',
    mgaUsers: '/mgas/mga-users',
    authorizedCarrier: '/mgas/authorized-carriers',
    authMgas: '/mgas/authorized-mga',
    authProducts: '/mgas/authorized-products'

  },
  rating: {
    newRating: '/mgas/rating/create',
    updateRating: '/mgas/rating/edit'
  },
  carriers: { root: '/carriers' },
  distribution: {
    root: '/distribution',
    binding: '/distribution/binding',
    policy: '/distribution/policy',
    mgaSubmission: '/distribution/mga-submission',
    payment: '/distribution/payment',
    applicationForms: '/distribution/application-forms',
    quoteIndication: '/distribution/quote-indication'
  },
  users: { root: '/users' },
  applications: {
    root: '/application-list',
    create:'/applications/create',
    start: '/start-application',
    edit:'/applications/edit',
    verify: '/verify-application',
    verifyCurrentApplication:'/verify-current-application'
  },
  agents: { root: '/agents' },
  network: { root: '/network' },
  documentTemplate: {
    root: '/document-template',
    proposals: '/document-template/proposals'
  }
};
