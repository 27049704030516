import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_BASE_URL, API_KEY } from 'constant/apiConstant';

export const policyApi = createApi({
  reducerPath: 'policyApi',
  tagTypes: ['Post'],
  keepUnusedDataFor: 1,
  baseQuery: fetchBaseQuery({ baseUrl: API_BASE_URL }),
  endpoints: (builder) => ({
    getPolicyList: builder.query({
      query: (payload) => ({
        url: `/policy/list?limit=${payload.limit}&offset=${payload.offset}&effectiveDate=${payload.effectiveDate}&expirationDate=${payload.expirationDate}&productId=${payload.productId}&carrierId=${payload.carrierId}&agencyId=${payload.agencyId}&status=${payload.status}&searchBy=${payload.searchBy}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),

    getPolicyDetails: builder.query({
      query: (payload) => ({
        url: `/policy/get-single-policy?policyId=${payload.policyId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getPolicyDetailsV2: builder.query({
      query: (payload) => ({
        url: `application/policy/get-policy-details?submissionResponseId=${payload.submissionResponseId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      transformResponse: (response) => response.result
    }),
    getPolicyPayment: builder.query({
      query: (payload) => ({
        url: `/payment/get-distribution-payment-list-v2?agecyId=${payload.agencyId}&limit=${payload.limit}&offset=${payload.offset}&policyId=${payload.policyId}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      })
    }),

    getAgencyProducts: builder.query({
        query: (payload) => ({
          url: `/company-product/get-agency-products?agencyId=${payload.agencyId}&limit=${payload.limit}&offset=${payload.offset}&searchBy=${payload.searchBy}&status=${payload.status}`,
          method: 'GET',
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
            'X-API-KEY': API_KEY,
            Authorization: `Bearer ${payload.token}`
          }
        })
      }),

    addPolicy: builder.mutation({
      query: (payload) => ({
        url: '/policy/create',
        method: 'POST',
        body: payload.processedData,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    }),
    updatePolicy: builder.mutation({
      query: (payload) => ({
        url: '/policy/update',
        method: 'PUT',
        body: payload.data,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: `Bearer ${payload.token}`
        }
      }),
      invalidatesTags: ['Post']
    })
  })
});

export const {
  useAddPolicyMutation,
  useGetPolicyListQuery,
  useGetPolicyDetailsQuery,
  useUpdatePolicyMutation,
  useLazyGetAgencyProductsQuery,
  useLazyGetPolicyPaymentQuery,
  useGetPolicyPaymentQuery,
  useGetPolicyDetailsV2Query
} = policyApi;