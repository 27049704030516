/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/no-cycle */
import React from "react";
import { createBrowserRouter } from "react-router-dom";

import ErrorPage from "pages/404";
import { authWrapper } from "utils/authWrapper";
import {
  AgencyAdminAccessRoles,
  superAdminAccessRoles
} from "constant/authRole";
import { path } from "constant/pathLinksConstant";

import {suspenseWrapper} from "../utils/suspenseWrapper";
import { usersRoute } from "./userRoute";

export const AppRouter = createBrowserRouter([
  {
    path: "/",
    async lazy() {
      const { HomePage } = await import("../pages/home");
      return {
        Component: () =>
          authWrapper(<HomePage />, "auth", AgencyAdminAccessRoles)
      };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.applications.root,
    async lazy() {
      const { ApplicationList } = await import(
        "../pages/Application/ApplicationList"
      );
      return {
        Component: () =>
          authWrapper(<ApplicationList />, "auth", AgencyAdminAccessRoles)
      };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },

  {
    path: "/success-payment/applications/:applicationId/:submissionResponseId/:paymentMethod",
    async lazy() {
      const { PaymentSuccessPage } = await import(
        "../pages/Application/NewApplication/ApplicationSteps/PaymentSuccess"
      );
      return { Component: () => authWrapper(<PaymentSuccessPage />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.app.customerThankYou,
    async lazy() {
      const { CustomerThankYou } = await import("../pages/CustomerThankYou");
      return { Component: () => authWrapper(<CustomerThankYou />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.app.login,
    async lazy() {
      const { LoginPage } = await import("../pages/Onboarding/Login");
      return { Component: () => authWrapper(<LoginPage />, "unAuth") };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.app.verifyAdmin,
    async lazy() {
      const { VerifyCustomer } = await import("../pages/VerifyCustomer");
      return { Component: () => authWrapper(<VerifyCustomer />, "unAuth") };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },

  {
    path: path.app.verifyCustomerPayment,
    async lazy() {
      const { PaymentByCustomer } = await import("../pages/PaymentByCustomer");
      return { Component: () => authWrapper(<PaymentByCustomer />, "unAuth") };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.app.producerAgreement,
    async lazy() {
      const { ProducerAgreement } = await import("../pages/ProducerAgreement");
      return { Component: () => authWrapper(<ProducerAgreement />, "unAuth") };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.app.additionalQuestion,
    async lazy() {
      const { AdditionalQuestion } = await import("../pages/AdditionalQuestion");
      return { Component: () => authWrapper(<AdditionalQuestion />, "unAuth") };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.app.agentSignup,
    async lazy() {
      const { AgentSignUpPage } = await import("../pages/Onboarding/Signup");
      return { Component: () => authWrapper(<AgentSignUpPage />, "unAuth") };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.app.forgotPassword,
    async lazy() {
      const { ForgotPassword } = await import(
        "../pages/Onboarding/ForgotPassword"
      );
      return { Component: () => authWrapper(<ForgotPassword />, "unAuth") };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.app.resetPassword,
    async lazy() {
      const { ResetPassword } = await import(
        "../pages/Onboarding/ResetPassword"
      );
      return { Component: () => authWrapper(<ResetPassword />, "unAuth") };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.app.checkEmail,
    async lazy() {
      const { CheckEmail } = await import(
        "../pages/Onboarding/ForgotPassword/CheckEmail"
      );
      return { Component: () => authWrapper(<CheckEmail />, "unAuth") };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: "/products",
    async lazy() {
      const { Products } = await import("../pages/Products/Products");
      return {
        Component: () =>
          authWrapper(<Products />, "auth", AgencyAdminAccessRoles)
      };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },

  {
    path: path.applications.start,
    async lazy() {
      const { NewApplication } = await import(
        "../pages/Application/NewApplication/NewApplication"
      );
      return {
        Component: () =>
          authWrapper(<NewApplication />, "unAuth")
      };
    },

    errorElement: suspenseWrapper(<ErrorPage />)
  },

  {
    path: path.applications.create,
    async lazy() {
      const { CreateApplication } = await import(
        "../pages/Application/CreateApplication"
      );
      return {
        Component: () =>
          authWrapper(<CreateApplication />, "auth", [
            ...AgencyAdminAccessRoles,
            ...superAdminAccessRoles
          ])
      };
    },

    errorElement: suspenseWrapper(<ErrorPage />)
  },

  {
    path: path.applications.edit,
    async lazy() {
      const { CreateApplication } = await import(
        "../pages/Application/CreateApplication"
      );
      return {
        Component: () =>
          authWrapper(<CreateApplication />, "auth", [
            ...AgencyAdminAccessRoles,
            ...superAdminAccessRoles
          ])
      };
    },

    errorElement: suspenseWrapper(<ErrorPage />)
  },

  {
    path: path.applications.verify,
    async lazy() {
      const { VerifyApplication } = await import(
        "../pages/Application/VerifyApplication"
      );
      return { Component: () => authWrapper(<VerifyApplication />, "unAuth") };
    },

    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.applications.verifyCurrentApplication,
    async lazy() {
      const { VerifyApplication } = await import(
        "../pages/VerifyApplication"
      );
      return { Component: () => authWrapper(<VerifyApplication />, "unAuth") };
    },

    errorElement: suspenseWrapper(<ErrorPage />)
  },


  {
    path: path.app.verifyNewAgency,
    async lazy() {
      const { VerifyNewAgency } = await import('../pages/VerifyNewAgency');
      return { Component: () => suspenseWrapper(<VerifyNewAgency />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },

  {
    path: path.app.verifyConsumerCustomer,
    async lazy() {
      const { VerifyConsumerCustomer } = await import(
        "../pages/Application/VerifyConsumerCustomer"
      );
      return { Component: () => <VerifyConsumerCustomer /> };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.app.verifyPartnerAgent,
    async lazy() {
      const { VerifyPartnerAgent } = await import(
        "../pages/Application/VerifyPartnerAgent"
      );
      return { Component: () => <VerifyPartnerAgent /> };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.app.thankYou,
    async lazy() {
      const { ThankYou } = await import('../pages/Thankyou');
      return { Component: () => suspenseWrapper(<ThankYou />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.users.root,
    async lazy() {
      const { UsersPage } = await import("../pages/users");
      return {
        Component: () =>
          authWrapper(<UsersPage />, "auth", [
            ...AgencyAdminAccessRoles,
            ...superAdminAccessRoles
          ])
      };
    },
    children: usersRoute,
    errorElement: suspenseWrapper(<ErrorPage />)
  },

  // {
  //   path: path.serviceRequests.root,
  //   async lazy() {
  //     const { Demo } = await import(
  //       "../pages/ServiceRequests/ServiceRequests"
  //     );
  //     return {
  //       Component: () =>
  //         authWrapper(<Demo />, "auth", [
  //           ...AgencyAdminAccessRoles,
  //           ...superAdminAccessRoles
  //         ])
  //     };
  //   },

  //   errorElement: suspenseWrapper(<ErrorPage />)
  // },

  {
    path: path.policies.root,
    async lazy() {
      const { Policies } = await import(
        "../pages/Policies/Policies"
      );
      return {
        Component: () =>
          authWrapper(<Policies />, "auth", [
            ...AgencyAdminAccessRoles,
            ...superAdminAccessRoles
          ])
      };
    },
 errorElement: suspenseWrapper(<ErrorPage />)
  },
                               
  {
    path: path.app.verifyNewAgency,
    async lazy() {
      const { VerifyNewAgency } = await import('../pages/VerifyNewAgency');
      return { Component: () => suspenseWrapper(<VerifyNewAgency />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.app.verifyCustomerApplication,
    async lazy() {
      const { VerifyUserToken } = await import('../pages/VerifyUserToken');
      return { Component: () => suspenseWrapper(<VerifyUserToken />) };
    },
    errorElement: suspenseWrapper(<ErrorPage />)
  },
  {
    path: path.reports.root,
    async lazy() {
      const { Reports } = await import(
        "../pages/Reports/Reports"
      );
      return {
        Component: () =>
          authWrapper(<Reports />, "auth", [
            ...AgencyAdminAccessRoles
          ])
      };
    },

    errorElement: suspenseWrapper(<ErrorPage />)
  }


]);
